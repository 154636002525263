<template>
  <div>
    <el-card>
      <div class="topDiv">
        <el-input v-model="params.companyName" placeholder="请输入"></el-input>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
        <!-- <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addInfoClick()">添加</el-button> -->
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="companyName" label="公司名称" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="companyNo" label="公司编号" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="linkmanName" label="联系人" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" min-width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!--  编辑公司/ 新增公司-->
    <el-dialog :title="rowData ? '编辑公司' : '新增公司'" :visible.sync="gongSiDia" v-if="gongSiDia" width="600px" @close="gongSiDia = false">
      <gongSiDia :rowData="rowData" @quXiaoClick="gongSiDia = false" @diaQueDingClick="diaQueDingClick"></gongSiDia>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import gongSiDia from '@/components/gongSiDia.vue'

export default {
  components: { pagination, gongSiDia },
  mixins: [commonMixin],
  data() {
    return {
      gongSiDia: false,
      params: { pageNum: 1, pageSize: 10, companyName: '' },
      records: [],
      total: 0,
      rowData: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    //
    async getData() {
      console.log(this.params)
      const res = await this.$API.gongSi.get(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = parseInt(total)
    },

    // 添加
    addInfoClick() {
      this.rowData = ''
      this.gongSiDia = true
    },
    // 编辑
    updateClick(row) {
      this.rowData = row
      this.gongSiDia = true
    },
    // 弹框上 确定 click
    async diaQueDingClick() {
      this.gongSiDia = false
      this.getData()
    },
    searchClick() {
      this.params.pageNum = 1
      this.getData()
    },

    //  删除
    async deleteItem(row) {
      const params = {
        id: row.id,
      }
      this.$confirm('此操作将永久删除该公司, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.gongSi.delGongSi(params)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
          }
        })
        .catch((err) => err)
    },
  },
}
</script>

<style lang="scss" scoped>
.topDiv {
  display: flex;
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
