<template>
  <div>
    <el-form :model="myForm" ref="editFormRef" :rules="rules" label-width="100px" size="small">
      <el-form-item label="公司名称" prop="companyName">
        <el-input v-model="myForm.companyName" placeholder="请输入公司名称"></el-input>
      </el-form-item>

      <el-form-item label="公司编号" prop="companyNo">
        <el-input v-model="myForm.companyNo" placeholder="请输入公司编号"></el-input>
      </el-form-item>

      <el-form-item label="联系人" prop="linkmanName">
        <el-input v-model="myForm.linkmanName" placeholder="请输入联系人"></el-input>
      </el-form-item>

      <el-form-item label="手机号" prop="linkmanPhone">
        <el-input v-model="myForm.linkmanPhone" placeholder="请输入手机号"></el-input>
      </el-form-item>

      <el-form-item>
        <div class="btnDiv">
          <el-button @click="quXiaoClick">取 消</el-button>
          <el-button type="primary" @click="diaQueDingClick">确 定</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/* eslint-disable */
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { makeForm } from '@/utils/tools.js'
export default {
  props: ['rowData'],
  components: { uploadImg },
  data() {
    return {
      myForm: {
        companyName: '', // 公司名称
        companyNo: '', // 公司编号
        linkmanName: '', // 联系人
        linkmanPhone: '', // 手机号
        id: '',
      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        companyNo: [{ required: true, message: '请输入公司编号', trigger: 'blur' }],
      },
    }
  },
  created() {},
  mounted() {
    if (this.rowData) {
      makeForm(this.myForm, this.rowData)
    }
    console.log('this.myForm', this.myForm)
  },
  methods: {
    // 确定
    diaQueDingClick() {
      this.$refs['editFormRef'].validate(async (valid) => {
        if (valid) {
          // 编辑
          if (this.rowData) {
            const res = await this.$API.gongSi.editGongSi(this.myForm)
            if (res.status !== 200) return this.$message.error(res.msg)
            this.$emit('diaQueDingClick')
          } else {
            const res = await this.$API.gongSi.addGongSi(this.myForm)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$emit('diaQueDingClick')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消
    quXiaoClick() {
      this.$emit('quXiaoClick')
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.btnDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
